import { useState } from "react";
import {
    AppBar,
    Box,
    Button,
    IconButton,
    Toolbar,
    Typography,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { Image } from "mui-image";

interface MyAppBarProps {
    title: string,
}

const MyAppBar: React.FC<MyAppBarProps> = (props: MyAppBarProps) => {
    const [title, setTitle] = useState(props.title);

    return (
        <Box sx={{
            flexGrow: 1,
        }}>
            <AppBar
                position="sticky"
                sx={{
                    bgcolor: '#fff5ee',
                    color: '#000',
                }}
            >
                <Toolbar>
                    <Box sx={{
                        mr: 2
                    }}>
                        <img
                            src="assets/logo.png"
                            width={ 48 }
                            height={ 48 }
                        />
                    </Box>
                    
                    {/*<IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        sx={{ mr: 2 }}
                    >
                        <MenuIcon />
                    </IconButton>*/}
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>{
                        title
                    }</Typography>
                </Toolbar>
            </AppBar>
        </Box>
    );
};

export default MyAppBar;