import React, { useContext, useEffect, useState } from "react";
import { Route } from 'react-router-dom';
import {
    IonApp,
    IonIcon,
    IonLabel,
    IonRouterOutlet,
    IonTabBar,
    IonTabButton,
    IonTabs,
    setupIonicReact,
} from '@ionic/react';
//import { IonTabsCustomEvent } from "@ionic/react-router";
import { IonReactRouter } from '@ionic/react-router';
import { home, logIn, logOut, personAddOutline } from 'ionicons/icons';

import PageSso from './pages/PageSso';

import TabHome from './pages/TabHome';
import TabLogin from './pages/TabLogin';
import TabLogout from './pages/TabLogout';
import TabRegister from './pages/TabRegister';
import TabRegisterSuccess from './pages/TabRegisterSuccess';
import TabForgot from './pages/TabForgot';
import TabForgotSuccess from './pages/TabForgotSuccess';
import TabReset from "./pages/TabReset";
import TabResetSuccess from "./pages/TabResetSuccess";
import { ThemeProvider } from '@mui/material/styles';
import PageEditProfile from './pages/PageEditProfile';
import PageEditProfileSuccess from './pages/PageEditProfileSuccess';
import PageEditPassword from './pages/PageEditPassword';
import PageEditPasswordSuccess from './pages/PageEditPasswordSuccess';
import { Helmet } from 'react-helmet';

import { getUrl } from "./components/Urls";
import { getAuthHeader } from "./components/Auth";

import {
    theme,
} from "./components/Global";

import {
    HOME_NAME,
    LOGIN_NAME,
    LOGOUT_NAME,
    REGISTER_NAME,
    HEADERS_JSON,
} from "./components/Global";

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Roboto Font */
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';


/* Theme variables */
import './theme/variables.css';

setupIonicReact();

export interface TenantProps {
    hashid: string,
    id: number,
};

interface TenantContextProps {
    tenant: TenantProps | null,
};

export const TenantContext = React.createContext<TenantContextProps>({
    tenant: null,
});

const App: React.FC = () => {
    //const tenantContext = useContext(TenantContext);

    const [tenantContext, setTenantContext] = useState<TenantContextProps>({
        tenant: null,
    });
    const [token, setToken] = useState<string | null>(null);
    const [user, setUser] = useState<any>(null);
    const [isFetched, setIsFetched] = useState(false);
    const [profileUpdated, setProfileUpdated] = useState<Date | null>(null);

    useEffect(() => {
        fetchUserProfileInfo();
    }, [token, profileUpdated]);


    async function fetchUserProfileInfo() {
        const url = getUrl('userProfileInfo');

        const response = await fetch(url, {
            method: 'GET',
            headers: getAuthHeader(HEADERS_JSON),
        });

        const { data, message } = await response.json();

        setIsFetched(true);

        if (response.ok) {
            setUser(data);
        }
        else {
            setUser(null);
            console.log('error', message);
        }
    }

    return (
        <TenantContext.Provider value={tenantContext}>
            <ThemeProvider theme={theme}>
                <IonApp>
                    <Helmet>
                        <title>Finworld Account</title>
                    </Helmet>
                    <IonReactRouter forceRefresh>
                        <IonTabs onIonTabsWillChange={(e) => {

                        }}>
                            <IonRouterOutlet>
                                <Route exact path="/">
                                    <TabHome
                                        user={user}
                                        isFetched={isFetched}
                                        setTenantContext={setTenantContext}
                                    />
                                </Route>

                                <Route path="/sso/:tenant_hashid/:samlRequest" component={PageSso} />

                                <Route exact path="/login">
                                    <TabLogin setTenantContext={setTenantContext} user={user} setToken={setToken} />
                                </Route>

                                <Route exact path="/logout">
                                    <TabLogout user={user} setToken={setToken} />
                                </Route>

                                <Route exact path="/register">
                                    <TabRegister user={user} />
                                </Route>
                                <Route exact path="/forgot">
                                    <TabForgot user={user} />
                                </Route>
                                <Route exact path="/reset">
                                    <TabReset />
                                </Route>

                                <Route exact path="/register-success">
                                    <TabRegisterSuccess />
                                </Route>
                                <Route exact path="/forgot-success">
                                    <TabForgotSuccess />
                                </Route>
                                <Route exact path="/reset-success">
                                    <TabResetSuccess />
                                </Route>

                                <Route exact path="/edit-profile">
                                    <PageEditProfile
                                        user={user}
                                        isFetched={isFetched}
                                        setProfileUpdated={setProfileUpdated}
                                    />
                                </Route>
                                <Route exact path="/edit-profile-success">
                                    <PageEditProfileSuccess user={user} />
                                </Route>

                                <Route exact path="/change-password">
                                    <PageEditPassword
                                        user={user}
                                        isFetched={isFetched}
                                    />
                                </Route>
                                <Route exact path="/change-password-success">
                                    <PageEditPasswordSuccess user={user} />
                                </Route>
                            </IonRouterOutlet>
                            <IonTabBar slot="bottom">

                                <IonTabButton tab="tabHome" href="/">
                                    <IonIcon icon={home} />
                                    <IonLabel>{HOME_NAME}</IonLabel>
                                </IonTabButton>


                                {!user ? (
                                    <IonTabButton tab="tabLogin" href="/login">
                                        <IonIcon icon={logIn} />
                                        <IonLabel>{LOGIN_NAME}</IonLabel>
                                    </IonTabButton>
                                ) : null}

                                {!user ? (
                                    <IonTabButton tab="tabRegister" href="/register">
                                        <IonIcon icon={personAddOutline} />
                                        <IonLabel>{REGISTER_NAME}</IonLabel>
                                    </IonTabButton>
                                ) : null}

                                {user ? (
                                    <IonTabButton tab="tabLogout" href="/logout">
                                        <IonIcon icon={logOut} />
                                        <IonLabel>{LOGOUT_NAME}</IonLabel>
                                    </IonTabButton>
                                ) : null}
                            </IonTabBar>
                        </IonTabs>
                    </IonReactRouter>
                </IonApp>
            </ThemeProvider>
        </TenantContext.Provider>
    );
}

export default App;
