import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import EmailValidator from "email-validator";
import LoginIcon from '@mui/icons-material/Login';
import { 
    IonContent,
    IonHeader,
    IonPage,
} from '@ionic/react';
import { 
    Box,
    Button,
    Checkbox,
    Container,
    Divider,
    Grid,
    IconButton,
    InputAdornment,
    Link,
    TextField,
} from '@mui/material';
import {
    Visibility,
    VisibilityOff,
} from "@mui/icons-material";
import { setAuth } from "../components/Auth";
import MyAppBar from "../components/MyAppBar";
import {
    LOGIN_NAME,
    REGISTER_NAME,
    FORGOT_PASSWORD_NAME,
    PASSWORD_MIN_LENGTH,
    HEADERS_JSON,
} from "../components/Global";
import { getUrl, getExtUrl } from "../components/Urls";

import { FormSsoHidden, SamlResponse } from "../pages/PageSso";

import "./TabLogin.css";
import { useVerifyTenant } from "../components/MultiTenantHelpers";

interface FormLoginProps {
    samlRequest: string|null,
    setToken: Function,
    setSamlResponse: Function,
};

const FormLogin: React.FC<FormLoginProps> = (props: FormLoginProps) => {   
    const history = useHistory();

    const BACKWARD_COMPAT_PASSWORD_MIN_LENGTH = 6;

    const [isError, setIsError] = useState<boolean>(false);
    const [authErrorMessage, setAuthErrorMessage] = useState("");

    const [samlRequest, setSamlRequest]= useState(props.samlRequest);
    
    const [textFields, setTextFields] = useState({
        email: "", //"mgmt@finworld.com.my",
        password: "", //"P@$$word",
    });

    const [checkboxes, setCheckboxes] = useState({
        remember: true,
    });

    const [showPassword, setShowPassword] = useState(false);

    const handleChangeTextField = (e: React.ChangeEvent<HTMLInputElement>) => {
        setTextFields({
            ...textFields,
            [e.target.name]: e.target.value,
        });
    };

    const handleChangeCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCheckboxes({
            ...checkboxes,
            [e.target.name]: e.target.checked,
        });
    };

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
    };

    const loginProcedure = () => {
        setIsError(false);
        setAuthErrorMessage("");
        fetchLogin();
    }

    const handleLoginButtonClick = () => {
        loginProcedure();
    }

    const handleInputKeyDown = (e: React.KeyboardEvent) => {
        if(e.key === 'Enter' && EmailValidator.validate(textFields.email) && textFields.password.length >= BACKWARD_COMPAT_PASSWORD_MIN_LENGTH) {
            loginProcedure();
        }
    }

    useEffect(() => {
        setSamlRequest(props.samlRequest);
    }, [props.samlRequest]);

    async function fetchLogin() {
        const { setToken, setSamlResponse } = props;
        const url = getUrl('login');
    
        const requestBody = {
            email: textFields.email,
            password: textFields.password,
            remember: checkboxes.remember,
            r: samlRequest,
        };
    
        const response = await fetch(url, {
            method: 'POST',
            headers: HEADERS_JSON,
            body: JSON.stringify(requestBody),
        });

        const {token, expires, saml, message, errors} = await response.json();

        if(response.ok) {
            if(saml) {
                setSamlResponse(saml);
            }

            setToken(token);
            setAuth(token, expires);
        }
        else if(response.status === 422) {
            setIsError(true);
            setAuthErrorMessage(message);
        }
        else if(response.status === 429) {
            setIsError(true);
            setAuthErrorMessage("Too many attempts. Please wait a while before trying");
        }
        else {
            setIsError(true);
            console.log('error', errors);
        }
    }

    return (
        <Box sx={{
            flexGrow: 1,
            margin: '32px',
        }}>
            <Container maxWidth="sm">
                <form>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                id="email"
                                name="email"
                                label="Email"
                                type="email"
                                value={textFields.email}
                                error={ isError }
                                autoFocus
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                onChange={handleChangeTextField}
                                onKeyDown={handleInputKeyDown}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                id="password"
                                name="password"
                                label="Password"
                                type={showPassword ? "text" : "password"}
                                value={textFields.password}
                                error={ isError }
                                helperText={ isError ? authErrorMessage : '' }
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                onChange={handleChangeTextField}
                                onKeyDown={handleInputKeyDown}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                            >{
                                                showPassword ? <VisibilityOff /> : <Visibility />
                                            }</IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>

                        {/*<Grid item xs={12}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        id="remember"
                                        name="remember"
                                        checked={checkboxes.remember}
                                        onChange={handleChangeCheckbox}
                                        inputProps={{
                                            'aria-label': 'controlled',
                                        }}
                                    />
                                }
                                label="Remember Me"
                                labelPlacement="end"
                            />
                        </Grid>*/}

                        <Box sx={{m: 1}} />

                        <Grid item xs={12}>
                            <Button
                                size="large"
                                variant="contained"
                                fullWidth
                                disabled={!(EmailValidator.validate(textFields.email) && textFields.password.length >= BACKWARD_COMPAT_PASSWORD_MIN_LENGTH)}
                                onClick={handleLoginButtonClick}
                                startIcon={<LoginIcon />}
                            >{LOGIN_NAME}</Button>
                        </Grid>

                        <Box sx={{m: 1}} />

                        <Grid item xs={12}>
                            <Link
                                color="error"
                                href="/forgot"
                                underline="hover"
                            >{FORGOT_PASSWORD_NAME}</Link>
                        </Grid>

                        <Grid item xs={12}>
                            <Divider />
                        </Grid>

                        <Box sx={{m: 1}} />

                        <Grid item xs={12}>
                            <Button
                                href="/register" 
                                color="success"
                                variant="contained"
                            >{REGISTER_NAME}</Button>
                        </Grid>

                        <Box sx={{m: 1}} />

                        <Grid item xs={12}>
                            <Button
                                href={ `${getExtUrl('stock')}?viewAs=guest` }
                                color="success"
                                variant="outlined"
                            >Browse Stock App as Guest</Button>
                        </Grid>
                    </Grid>
                </form>
            </Container>
            
        </Box>
    );
};

interface TabLoginProps {
    setTenantContext: Function,
    user: any,
    setToken: Function,
};

const TabLogin: React.FC<TabLoginProps> = (props: TabLoginProps) =>  {
    const params = new URLSearchParams(window.location.search);

    const samlRequest = params.get("sso");

    const history = useHistory();

    const [samlResponse, setSamlResponse] = useState<SamlResponse|null>(null);

    const t = useVerifyTenant();

    useEffect(() => {
        if (t) {
            props.setTenantContext({
                tenant: t,
            });
        }
    }, [t]);

    useEffect(() => {
        if(props.user != null) {
            if(samlResponse == null) {
                history.push('/');
            }
        }
    }, [props.user]);

    return samlResponse == null ? (
        <IonPage>
            <IonHeader>
                <MyAppBar
                    title={LOGIN_NAME}
                />
            </IonHeader>
            
            <IonContent>
                <FormLogin
                    samlRequest={samlRequest}
                    setToken={props.setToken}
                    setSamlResponse={setSamlResponse}
                />
            </IonContent>
        </IonPage>
    ) : (
        <FormSsoHidden
            tenant_hashid={t?.hashid}
            samlResponse={samlResponse}
        />
    );
};

export default TabLogin;