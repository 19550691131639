import Cookies from "js-cookie";
import {
    encrypt,
    decrypt,
} from "../components/Crypto";

export const setAuth = (token: string, expires: number) => {
    const cookieData = encrypt(JSON.stringify({
        t: encrypt(token),
    }));

    Cookies.set('s', cookieData, {
        expires: expires,
        secure: true,
    });

    return cookieData;
};

export const clearAuth = () => {
    Cookies.remove('s');
};

export const getAuth = () => {
    const sCookie = Cookies.get('s');
    if(sCookie == undefined) return null;

    const sDecrypted = decrypt(sCookie);
    if(sDecrypted == undefined) return null;

    const s = JSON.parse(sDecrypted);
    if(s == undefined) return null;

    return {
        t: decrypt(s.t),
    }
};

interface HttpHeaders {
    Accept: string,
    'Content-Type': string,
}

export const getAuthHeader = (headers: HttpHeaders) => {
    const auth = getAuth();

    return {
        ...headers,
        Authorization: `Bearer ${auth ? auth.t : ''}`,
    };
}