import React, { useEffect, useMemo, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Box, Button, Container, FormControl, FormHelperText, Grid, IconButton, InputLabel, MenuItem, Paper, Select, SelectChangeEvent, Stack, TextField } from "@mui/material";
import MaterialReactTable, { MRT_ColumnDef } from "material-react-table";
import SaveIcon from '@mui/icons-material/Save';
import { getUrl } from "../../components/Urls";
import { getAuth, getAuthHeader } from "../../components/Auth";
import { HEADERS_JSON, VALIDATION_MESSAGES } from "../../components/Global";
import EditIcon from '@mui/icons-material/Edit';
import { useIonAlert } from "@ionic/react";

const STATUSES = [
    {
        value: 'A',
        label: 'Active',
    },
    {
        value: 'X',
        label: 'Inactive',
    },
];

const INIT_INPUT_STATE = {
    name: "",
    description: "",
    status: "X",
};

interface ServicesProps {
    user: any,
};

interface ServiceColumn {
    id: number;
    name: string;
    description: string;
    status: string;
    created_at: Date;
    updated_at: Date;
};

const Services: React.FC<ServicesProps> = (props: ServicesProps) => {
    const history = useHistory();
    const [presentAlert] = useIonAlert();

    const [services, setServices] = useState<ServiceColumn[]>([]);
    const [isAdding, setIsAdding] = useState(false);
    const [editingIndex, setEditingIndex] = useState(-1);

    const [serviceAttrs, setServiceAttrs] = useState(INIT_INPUT_STATE);

    const [inputErrors, setInputErrors] = useState<any>({
        name: false,
        description: false,
        status: false,
    });

    const [inputErrorMessages, setInputErrorMessages] = useState<any>({
        name: [VALIDATION_MESSAGES.name],
        status: [VALIDATION_MESSAGES.status],
    });

    const handleChangeTextField = (e: React.ChangeEvent<HTMLInputElement>) => {
        setServiceAttrs({
            ...serviceAttrs,
            [e.target.name]: e.target.value,
        });
    };

    const handleChangeSelect = (e: SelectChangeEvent) => {
        setServiceAttrs({
            ...serviceAttrs,
            [e.target.name]: e.target.value as string,
        });
    }

    const handleSave = () => {
        setIsAdding(false);
        setEditingIndex(-1);
        setServiceAttrs(INIT_INPUT_STATE);
        saveServices();
    }

    async function fetchServices() {
        const url = getUrl('adminServices');

        const response = await fetch(url, {
            method: "GET",
            headers: getAuthHeader(HEADERS_JSON),
        });

        const { data } = await response.json();

        if (response.ok) {
            const ss: ServiceColumn[] = [];

            data.map((d: any, i: number) => {
                const s: ServiceColumn = {
                    id: d.id,
                    name: d.name,
                    description: d.description,
                    status: d.status,
                    created_at: new Date(d.created_at),
                    updated_at: new Date(d.updated_at),
                };

                ss.push(s);
            });
            setServices(ss);
        }
        else {

        }
    };

    async function saveServices() {
        const url = editingIndex === -1 ? getUrl('adminServices') : `${getUrl('adminServices')}/${services[editingIndex]?.id}`;

        const requestBody = {
            ...serviceAttrs,
        };

        const response = await fetch(url, {
            method: editingIndex === -1 ? "POST" : "PUT",
            headers: getAuthHeader(HEADERS_JSON),
            body: JSON.stringify(requestBody),
        });

        if (response.ok) {
            fetchServices();
        }
        else if (response.status === 422) {
            const { errors } = await response.json();

            const errorAttrs = Object.keys(errors);
            const errorStatuses = Object.fromEntries(errorAttrs.map((_, i) => [errorAttrs[i], true]));

            setInputErrors({
                ...inputErrors,
                ...errorStatuses,
            })
            setInputErrorMessages({
                ...inputErrorMessages,
                ...errors,
            });
        }
        else {
            console.log('error');
        }
    };

    const columns = useMemo<MRT_ColumnDef<ServiceColumn>[]>(
        () => [
            {
                header: "Name",
                accessorKey: "name",
            },
            {
                accessorKey: "status",
                header: "Status",
                Cell: ({ cell }) => {
                    const status = cell.getValue<string>();
                    return STATUSES.find((x: any) => x.value === status)?.label;
                },
            },
        ],
        []
    );

    useEffect(() => {
        fetchServices();
    }, []);

    return (
        <Box sx={{
            flexGrow: 1,
            margin: '32px',
        }}>
            <Container maxWidth="md">
                <h3>Services</h3>
                <Box>
                    <Paper elevation={2} square sx={{ p: 1 }}>
                        <Grid container>
                            <Grid item xs={12} sx={{ p: 1 }}>
                                <Stack direction="row" spacing={2} justifyContent="end">
                                    <Button
                                        onClick={() => setIsAdding(true)}
                                    >
                                        Add Service
                                    </Button>
                                </Stack>
                            </Grid>

                            {isAdding || editingIndex > -1 ? (
                                <Grid item xs={12} sx={{ p: 1 }}>
                                    <Box>
                                        <Container maxWidth="sm">
                                            <form>
                                                <Grid container spacing={2}>

                                                    <Grid item xs={12}>
                                                        <TextField
                                                            id="name"
                                                            name="name"
                                                            label="Name"
                                                            type="text"
                                                            value={serviceAttrs.name}
                                                            error={inputErrors.name}
                                                            helperText={inputErrors.name ? inputErrorMessages.name.join('. ') : ''}
                                                            variant="outlined"
                                                            margin="normal"
                                                            fullWidth
                                                            onChange={handleChangeTextField}
                                                            onBlur={(e) => {
                                                                const isValid = e.target.value.length > 0;
                                                                setInputErrors({
                                                                    ...inputErrors,
                                                                    [e.target.name]: !isValid,
                                                                });
                                                                setInputErrorMessages({
                                                                    ...inputErrorMessages,
                                                                    [e.target.name]: [VALIDATION_MESSAGES.name],
                                                                });
                                                            }}
                                                        />
                                                    </Grid>

                                                    <Grid item xs={12}>
                                                        <TextField
                                                            id="description"
                                                            name="description"
                                                            label="Description"
                                                            type="text"
                                                            value={serviceAttrs.description}
                                                            variant="outlined"
                                                            margin="normal"
                                                            fullWidth
                                                            onChange={handleChangeTextField}
                                                        />
                                                    </Grid>

                                                    <Grid item xs={12}>
                                                        <FormControl sx={{ mt: 2, width: 268 }} error={inputErrors.status}>
                                                            <InputLabel id="label-status">Status</InputLabel>
                                                            <Select
                                                                id="status"
                                                                name="status"
                                                                label="Gender"
                                                                labelId="label-status"
                                                                value={serviceAttrs.status}
                                                                onChange={handleChangeSelect}
                                                                onBlur={(e) => {
                                                                    const isValid = e.target.value.length > 0;
                                                                    setInputErrors({
                                                                        ...inputErrors,
                                                                        [e.target.name]: !isValid,
                                                                    });
                                                                    setInputErrorMessages({
                                                                        ...inputErrorMessages,
                                                                        [e.target.name]: [VALIDATION_MESSAGES.status],
                                                                    });
                                                                }}
                                                            >{
                                                                    STATUSES.map(status => <MenuItem
                                                                        key={`status-${status.value}`}
                                                                        value={status.value}
                                                                    >
                                                                        {status.label}
                                                                    </MenuItem>)
                                                                }</Select>
                                                            <FormHelperText>{inputErrors.status ? inputErrorMessages.status.join('. ') : ''}</FormHelperText>
                                                        </FormControl>
                                                    </Grid>

                                                    <Box sx={{ m: 1 }} />

                                                    <Grid item xs={12}>
                                                        <Button
                                                            size="large"
                                                            variant="contained"
                                                            fullWidth
                                                            onClick={handleSave}
                                                            startIcon={<SaveIcon />}
                                                        >Save</Button>
                                                    </Grid>

                                                    <Grid item xs={12}>
                                                        <Button
                                                            size="large"
                                                            variant="outlined"
                                                            fullWidth
                                                            onClick={() => {
                                                                setIsAdding(false);
                                                                setEditingIndex(-1);
                                                            }}
                                                        >Cancel</Button>
                                                    </Grid>
                                                </Grid>
                                            </form>
                                        </Container>
                                    </Box>
                                </Grid>
                            ) : null}
                        </Grid>
                    </Paper>
                </Box>

                <MaterialReactTable
                    columns={columns}
                    data={services}
                    getRowId={(row) => `${row.id}`}
                    initialState={{ density: "compact" }}
                    enableRowNumbers
                    enableRowActions

                    muiTableBodyRowProps={({ row }) => ({
                        sx: {
                            cursor: 'pointer', //you might want to change the cursor too when adding an onClick
                        },
                    })}

                    renderRowActions={({ row }) => {
                        return (
                            <Box>
                                <IconButton onClick={(e) => {
                                    setEditingIndex(row.index);
                                    setServiceAttrs({
                                        name: row.original.name,
                                        description: row.original.description ? row.original.description : "",
                                        status: row.original.status,
                                    });

                                    e.stopPropagation();
                                }}>
                                    <EditIcon />
                                </IconButton>
                            </Box>
                        );
                    }}


                />

            </Container>
        </Box>
    );
};

export default Services;