import { useState, useEffect } from "react";
import CircularProgress from '@mui/material/CircularProgress';

interface MyProgressProps {
    text: string,
}

const MyProgress: React.FC<MyProgressProps> = (props: MyProgressProps) => {
    const [text, setText] = useState('');

    useEffect(() => {
        setText(props.text);
    }, [props.text]);

    return (
        <div style={{
            width: '240px',
            marginLeft: 'auto',
            marginRight: 'auto',
            paddingTop: '60px',
            paddingBottom: '60px',
        }}>
            <h1>{text}</h1>
            <CircularProgress color="inherit" />
        </div>
    );
};

export default MyProgress;