import React, { useEffect, useState } from "react";
import { HOME_NAME } from "../../components/Global";
import MyAppBar from "../../components/MyAppBar";
import MyProgress from "../../components/MyProgress";
import { Box, Container, Accordion, AccordionSummary, Typography, AccordionDetails } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Services from "./Services";

interface AdminProps {
    user: any,
};

const Admin: React.FC<AdminProps> = (props: AdminProps) => {
    const [expanded, setExpanded] = useState<string|boolean>(false);
    const [user, setUser] = useState<any|null>(null);

    const accordions = [
        // {
        //     id: 'panelUsers',
        //     title: 'Users',
        //     desc: 'Manage Users',
        //     content: <></>,
        // },
        {
            id: 'panelServices',
            title: 'Services',
            desc: 'Manage Services',
            content: <Services user={user} />,
        },
        // {
        //     id: 'panelTenants',
        //     title: 'Tenants',
        //     desc: 'Manage Tenants',
        //     content: <></>,
        // },
    ];

    const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false);
    };

    useEffect(() => {
        setUser(props.user);
    }, [props.user]);


    return (
        <Box sx={{
            flexGrow: 1,
            margin: '32px',
        }}>
            <Container maxWidth="md">
                <h2>Admin</h2>
                {accordions.map(a => <Accordion key={a.id} expanded={expanded === a.id} onChange={handleChange(a.id)}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={`${a.id}-content`}
                        id={`${a.id}-header`}
                    >
                        <Typography sx={{ width: '33%', flexShrink: 0 }}>
                            {a.title}
                        </Typography>
                        <Typography sx={{ color: 'text.secondary' }}>{a.desc}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {a.content}
                    </AccordionDetails>
                </Accordion>
                )}
            </Container>
        </Box>
    );
};

export default Admin;