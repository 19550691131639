import {
    format,
} from "date-fns";

export const getDatePickerErrorMessage = (reason: any, value: any, minDate: Date|null, maxDate: Date|null) => {
    switch (reason) {
        case "invalidDate":
            return "Invalid date";

        case "disablePast":
            return "Past dates are not allowed";

        case "maxDate":
            return maxDate != null ? `Date should not be after ${format(maxDate, "P")}` : 'Max date exceeded';

        case "minDate":
            return minDate != null ? `Date should not be before ${format(minDate, "P")}` : 'Min date exceeded';

        case "shouldDisableDate":
            return "Date input is disabled";

        default:
            return "";
    }
}