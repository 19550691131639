import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { 
    IonContent,
    IonPage,
} from '@ionic/react';

import { 
    Box,
    Container,
} from '@mui/material';
import {
    HEADERS_JSON,
} from "../components/Global";
import { getUrl } from "../components/Urls";
import { getAuthHeader, clearAuth } from "../components/Auth";
import MyProgress from "../components/MyProgress";

import "./TabLogout.css";

interface TabLogoutProps {
    user: any,
    setToken: Function,
};

interface SpProps {
    key: string,
    url: string,
};

interface IFrameRendererProps {
    src: string;
    iframeRef: any /*React.RefObject<HTMLIFrameElement>*/;
}


const IFrameRenderer: React.FC<IFrameRendererProps> = (props: IFrameRendererProps) => (
    <>
        <iframe
            ref={props.iframeRef}
            src={props.src}
            width={0}
            height={0}
            style={{ display: 'none' }}
        />
    </>
);

const TabLogout: React.FC<TabLogoutProps> = (props: TabLogoutProps) =>  {
    const params = new URLSearchParams(window.location.search);

    const samlRequest = params.get("sso");

    const history = useHistory();

    const [sps, setSps] = useState<any|null>(null);
    const [logoutRequest, setLogoutRequest] = useState<string>('');
    const [isIFrameLoaded, setIsIFrameLoaded] = useState<boolean[]>([]);
    const [areAllFramesLoaded, setAreAllFramesLoaded] = useState(false);
    const [listenerIndex, setListenerIndex] = useState<number|null>(null);

    const iFrameRefs = useRef<(HTMLIFrameElement|null)[]>([]);
    const iFrameCurrent = iFrameRefs.current;

    async function getLogoutSps() {
        const url = getUrl('ssoLogoutSps');

        const response = await fetch(url, {
            method: 'POST',
            headers: getAuthHeader(HEADERS_JSON),
        });

        const {LogoutRequest, sp} = await response.json();

        if(response.ok) {
            setLogoutRequest(LogoutRequest);
            setSps(sp);

            let temp: boolean[] = [];
            sp.forEach((s: SpProps, i: number) => {
                temp.push(false);
            });
            setIsIFrameLoaded(temp);
        }
        else {
            // force logout SP
            const forceUrl = `${document.referrer}sso/force-signout`;
            window.location.href = forceUrl;
        }
    }

    useEffect(() => {
        getLogoutSps();
    }, []);

    useEffect(() => {
        iFrameCurrent.forEach((current, i) => {
            current?.addEventListener('load', () => {
                let temp = isIFrameLoaded;
                temp[i] = true;
                setIsIFrameLoaded(temp);
                setListenerIndex(i);
            });
            return () => {
                current?.removeEventListener('load', () => {});
            };
        });
        
    }, [sps]);

    useEffect(() => {
        if(listenerIndex !== null) {
            let allFramesLoaded = true;

            isIFrameLoaded.forEach((f, i) => {
                if(!f) allFramesLoaded = false;
            });

            setAreAllFramesLoaded(allFramesLoaded);
        }
    }, [listenerIndex]);

    useEffect(() => {
        async function fetchLogout() {
            const { setToken } = props;
    
            const url = getUrl('logout');
        
            const response = await fetch(url, {
                method: 'POST',
                headers: getAuthHeader(HEADERS_JSON),
            });
            
            if(response.ok) {
                setToken(null);
                clearAuth();
                history.push('/login');
            }
            else {
                console.log('error');
            }
        };

        if(areAllFramesLoaded) {
            fetchLogout();
        }
    }, [areAllFramesLoaded]);

    return (
        <IonPage>
            <IonContent>
                <Box sx={{
                    flexGrow: 1,
                    margin: '32px',
                }}>
                    <Container maxWidth="sm">
                        <MyProgress text={'Signing out ...'} />
                    </Container>
                    <>
                        {sps ? sps.map((s: SpProps, i: number) => (
                            <IFrameRenderer
                                key={`iframe-${s.key}`}
                                src={`${s.url}?LogoutRequest=${encodeURIComponent(logoutRequest)}`}
                                iframeRef={(ref: HTMLIFrameElement) => iFrameRefs.current[i] = ref}
                            />
                        )) : null}
                    </>
                </Box>
            </IonContent>
        </IonPage>
    );
};

export default TabLogout;