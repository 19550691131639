import crypto from "crypto-js";

import { CKEY } from "./Global";

export const encrypt = (data: string) => {
    return crypto.AES.encrypt(data, CKEY).toString();
};

export const decrypt = (data: string) => {
    const bytes  = crypto.AES.decrypt(data, CKEY);
    return bytes.toString(crypto.enc.Utf8);
};
