import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { IonContent, IonHeader, IonPage, } from '@ionic/react';

import {
    Box,
    Button,
    Container,
    Grid,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';

import CandlestickChart from '@mui/icons-material/CandlestickChart';
import SchoolIcon from '@mui/icons-material/School';
import RestaurantIcon from '@mui/icons-material/Restaurant';

import MyProgress from "../components/MyProgress";
import { getExtUrl } from "../components/Urls";

import MyAppBar from "../components/MyAppBar";
import {
    SITE_NAME,
    HOME_NAME,
    PROFILE_INCOMPLETE_TITLE,
    PROFILE_INCOMPLETE_TEXT,
    PROFILE_INCOMPLETE_BUTTON,
    EDIT_PROFILE_NAME,
    CHANGE_PASSWORD_NAME,
    HEADERS_JSON,
} from "../components/Global";

import './TabHome.css';
import Admin from "./Admin/Admin";
import { useTenantHashid, useVerifyTenant } from "../components/MultiTenantHelpers";
import { TenantContext } from "../App";

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'left',
    color: theme.palette.text.secondary,
}));

interface HomeGuestProps {

};

const HomeGuest: React.FC<HomeGuestProps> = (props: HomeGuestProps) => {

    return (
        <>
            <h1></h1>
        </>
    );
};

interface MyAccountIncompleteProps {

};

const MyAccountIncomplete: React.FC<MyAccountIncompleteProps> = (props: MyAccountIncompleteProps) => {
    const history = useHistory();

    const handleCompleteProfileButtonClick = () => {
        history.push('/edit-profile');
    }

    return (
        <Box sx={{
            flexGrow: 1,
            margin: '32px',
        }}>
            <Container maxWidth="sm">
                <form>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <h1>{PROFILE_INCOMPLETE_TITLE}</h1>
                            <>{PROFILE_INCOMPLETE_TEXT}</>
                        </Grid>

                        <Box sx={{ m: 1 }} />

                        <Grid item xs={12}>
                            <Button
                                size="large"
                                variant="contained"
                                fullWidth
                                onClick={handleCompleteProfileButtonClick}
                                startIcon={<EditIcon />}
                            >{PROFILE_INCOMPLETE_BUTTON}</Button>
                        </Grid>

                    </Grid>
                </form>
            </Container>

        </Box>
    );
};

interface SectionAccountProfileProps {
    user: any,
}

const SectionAccountProfile: React.FC<SectionAccountProfileProps> = (props: SectionAccountProfileProps) => {
    const history = useHistory();

    const [user, setUser] = useState<any | null>(null);

    useEffect(() => {
        setUser(props.user);
    }, [props.user]);

    const handleButtonClick = () => {
        history.push('/edit-profile');
    }

    const viewAttrs = [
        {
            id: "attrName",
            name: "name",
            title: "Name",
            parent: false,
        },
        {
            id: "attrEmail",
            name: "email",
            title: "Email",
            parent: false,
        },
        {
            id: "attrPhoneMobile",
            name: "phone_mobile",
            title: "Phone (Mobile)",
            parent: true,
        },
    ];

    const items = [
        {
            id: "viewProfileInfo",
            title: "Profile Information",
            content: (<>
                <h3>Profile Summary</h3>
                <TableContainer>
                    <Table sx={{ minWidth: 650 }} aria-label="caption table">
                        <TableBody>
                            {viewAttrs.map(a => <TableRow key={a.id}>
                                <TableCell component="th" align="right">
                                    <Typography sx={{ fontWeight: 'bold', color: 'text.secondary' }}>
                                        {a.title}
                                    </Typography>
                                </TableCell>
                                <TableCell align="left">{user ? (a.parent ? user.user_profile[a.name] : user[a.name]) : null}</TableCell>
                            </TableRow>)}
                        </TableBody>
                    </Table>
                </TableContainer>
            </>),
        },
        {
            id: "editProfileInfo",
            title: "Edit Profile",
            content: (<>
                <h3>Edit Profile</h3>
                <p>Changed your email address, phone number or mailing address? Kindly provide us your updates...</p>
                <Button
                    size="large"
                    variant="contained"
                    onClick={handleButtonClick}
                    startIcon={<EditIcon />}
                >{EDIT_PROFILE_NAME}</Button>
            </>),
        },
    ];

    return (
        <Stack spacing={2}>
            {items.map(item => <Item key={item.id}>
                {item.content}
            </Item>)}
        </Stack>
    )
};

interface SectionAccountSecurityProps {

}

const SectionAccountSecurity: React.FC<SectionAccountSecurityProps> = (props: SectionAccountSecurityProps) => {
    const history = useHistory();

    const handleButtonClick = () => {
        history.push('/change-password');
    }

    const items = [
        {
            id: "changePassword",
            title: "Change Password",
            content: (<>
                <h3>Change Password</h3>
                <p>{`You are encouraged to change your password regularly to protect your account.`}</p>
                <Button
                    size="large"
                    variant="contained"
                    onClick={handleButtonClick}
                    startIcon={<EditIcon />}
                >{CHANGE_PASSWORD_NAME}</Button>
            </>),
        },
    ];

    return (
        <Stack spacing={2}>
            {items.map(item => <Item key={item.id}>
                {item.content}
            </Item>)}
        </Stack>
    )
};

interface AppListProps {

}

const AppList: React.FC<AppListProps> = (props: AppListProps) => {
    const tenantContext = useContext(TenantContext);
    const tenant = tenantContext?.tenant;

    const apps = [
        {
            id: 'stock',
            avatar: <CandlestickChart />,
            title: 'Stock',
            url: getExtUrl('stock', tenant),
        },
        {
            id: 'elearn',
            avatar: <SchoolIcon />,
            title: 'E-Learning',
            url: getExtUrl('elearn', tenant),
        },
        // {
        //     id: 'crm',
        //     avatar: <CandlestickChart />,
        //     title: 'CRM',
        //     url: getExtUrl('crm'),
        // },

    ];

    const ListContainer = styled('div')(({ theme }) => ({
        backgroundColor: theme.palette.background.paper,
    }));

    return (
        <Box sx={{
            flexGrow: 1,
            margin: '32px',
        }}>
            <Container maxWidth="md">
                <h2>Apps</h2>
                <Container style={{
                    backgroundColor: "gold",
                    borderRadius: '6px',
                    paddingTop: '24px',
                    paddingBottom: '24px',
                }}>
                    <ListContainer>
                        <List dense={false}>
                            {apps.map((app, i) => (
                                <React.Fragment
                                    key={`listItem-${i}`}
                                >
                                    <ListItemButton
                                        component="a"
                                        href={app.url}
                                    >
                                        <ListItemAvatar>
                                            <Avatar>
                                                {app.avatar}
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={app.title}
                                        />
                                    </ListItemButton>
                                    {i != apps.length - 1 ? <Divider /> : null}
                                </React.Fragment>
                            ))}
                        </List>
                    </ListContainer>
                </Container>


            </Container>
        </Box>
    );
};

interface MyAccountProps {
    user: any,
};

const MyAccount: React.FC<MyAccountProps> = (props: MyAccountProps) => {
    const [expanded, setExpanded] = useState<string | false>(false);
    const [user, setUser] = useState<any | null>(null);

    const accordions = [
        {
            id: 'panelProfile',
            title: 'Account Profile',
            desc: 'View & edit profile information',
            content: <SectionAccountProfile user={user} />,
        },
        {
            id: 'panelSecurity',
            title: 'Account Security',
            desc: 'Change your password',
            content: <SectionAccountSecurity />,
        },
    ];

    const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false);
    };

    useEffect(() => {
        setUser(props.user);
    }, [props.user]);


    return (
        <Box sx={{
            flexGrow: 1,
            margin: '32px',
        }}>
            <Container maxWidth="md">
                <h2>My Account</h2>
                {accordions.map(a => <Accordion key={a.id} expanded={expanded === a.id} onChange={handleChange(a.id)}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={`${a.id}-content`}
                        id={`${a.id}-header`}
                    >
                        <Typography sx={{ width: '33%', flexShrink: 0 }}>
                            {a.title}
                        </Typography>
                        <Typography sx={{ color: 'text.secondary' }}>{a.desc}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {a.content}
                    </AccordionDetails>
                </Accordion>
                )}
            </Container>
        </Box>
    );
};

interface HomeContentProps {
    user: any,
};

const HomeContent: React.FC<HomeContentProps> = (props: HomeContentProps) => {
    const [user, setUser] = useState<any>(null);

    useEffect(() => {
        setUser(props.user);
    }, [props.user]);

    const hasStaff2Abilities = user ? user?.abilities.filter((x: any) => x.name === 'aa_staff2').length > 0 : false;

    return (
        <>
            <AppList />
            <MyAccount user={user} />

            {hasStaff2Abilities ? <Admin user={user} /> : null}
        </>
    );
};

interface TabHomeProps {
    user: any,
    isFetched: boolean,
    setTenantContext: Function,
};

const TabHome: React.FC<TabHomeProps> = (props: TabHomeProps) => {
    const [user, setUser] = useState<any>(null);
    const [isFetched, setIsFetched] = useState(false);

    const t = useVerifyTenant();

    useEffect(() => {
        if (t) {
            props.setTenantContext({
                tenant: t,
            });
        }
    }, [t]);

    useEffect(() => {
        setUser(props.user);
    }, [props.user]);

    useEffect(() => {
        setIsFetched(props.isFetched);
    }, [props.isFetched]);

    return (
        <IonPage>
            <IonHeader>
                <MyAppBar
                    title={HOME_NAME}
                />
            </IonHeader>
            <IonContent fullscreen>
                {!isFetched ? (
                    <MyProgress text={'Loading ...'} />
                ) : (
                    user ? (
                        user.user_profile != undefined && user.user_profile != null ? <HomeContent user={user} /> : <MyAccountIncomplete />
                    ) : <HomeGuest />
                )
                }
            </IonContent>
        </IonPage>
    );
};

export default TabHome;
