import React, {
    useEffect, 
    useState,
} from "react";
import { useHistory } from "react-router-dom";
import { 
    IonContent, 
    IonHeader, 
    IonPage, 
    IonTitle, 
    IonToolbar,
} from '@ionic/react';
import { 
    Box,
    Button,
    Container,
    Grid,
    TextField,
} from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import EditIcon from '@mui/icons-material/Edit';
import {
    format,
    sub,
} from "date-fns";
import EmailValidator from "email-validator";

import { getUrl } from "../components/Urls";
import { getAuth, getAuthHeader } from "../components/Auth";
import MyAppBar from "../components/MyAppBar";
import { getDatePickerErrorMessage } from "../components/Utils";
import {
    VALIDATION_MESSAGES,
    EDIT_PROFILE_NAME,
    DATE_FORMAT_SHORT,
    HEADERS_JSON,
} from "../components/Global";
import {
    GENDERS,
    MY_STATES,
    COUNTRIES,
} from "../components/Demographics";

import "./PageEditProfile.css";

interface FormEditProfileProps {
    setProfileUpdated: Function,
};

const FormEditProfile: React.FC<FormEditProfileProps> = (props: FormEditProfileProps) => {
    const history = useHistory();

    const setProfileUpdated = props.setProfileUpdated;

    const today = new Date();
    const defaultBirthDate = new Date("1990-01-01");
    const minAge = {years: 12};
    const maxBirthDate = sub(today, minAge);

    const [userAttrs, setUserAttrs] = useState({
        id: 0,
        email: "",
        name: "",
        nationality: "MY",
        national_id: "",
        passport_issuer: "",
        passport_no: "",
        gender: "",
        birth_date: defaultBirthDate,
        phone_mobile: "",
        phone_home: "",
        phone_office: "",
        address: "",
        postcode: "",
        city: "",
        state: "",
        country: "MY",
    });

    const [inputErrors, setInputErrors] = useState<any>({
        email: false,
        name: false,
        nationality: false,
        national_id: false,
        passport_issuer: false,
        passport_no: false,
        gender: false,
        birth_date: false,
        phone_mobile: false,
        phone_home: false,
        phone_office: false,
        address: false,
        postcode: false,
        city: false,
        state: false,
        country: false,
    });

    const [inputErrorMessages, setInputErrorMessages] = useState<any>({
        email: [VALIDATION_MESSAGES.email],
        name: [VALIDATION_MESSAGES.name],
        nationality: [VALIDATION_MESSAGES.nationality],
        national_id: [VALIDATION_MESSAGES.national_id],
        passport_issuer: [VALIDATION_MESSAGES.passport_issuer],
        passport_no: [VALIDATION_MESSAGES.passport_no],
        gender: [VALIDATION_MESSAGES.gender],
        birth_date: [VALIDATION_MESSAGES.birth_date],
        phone_mobile: [VALIDATION_MESSAGES.phone_mobile],
        phone_home: [VALIDATION_MESSAGES.phone_home],
        phone_office: [VALIDATION_MESSAGES.phone_office],
        address: [VALIDATION_MESSAGES.address],
        postcode: [VALIDATION_MESSAGES.postcode],
        city: [VALIDATION_MESSAGES.city],
        state: [VALIDATION_MESSAGES.state],
        country: [VALIDATION_MESSAGES.country],
    });

    const handleChangeTextField = (e: React.ChangeEvent<HTMLInputElement>) => {
        setUserAttrs({
            ...userAttrs,
            [e.target.name]: e.target.value,
        });
    };

    const handleChangeSelect = (e: SelectChangeEvent) => {
        setUserAttrs({
            ...userAttrs,
            [e.target.name]: e.target.value as string,
        });
    }

    const topLevelAttrs = [
        'id',
        'email',
        'name',
    ];

    const userProfileAttrs = [
        'nationality',
        'national_id',
        'passport_issuer',
        'passport_no',
        'gender',
        'birth_date',
        'phone_mobile',
        'phone_home',
        'phone_office',
        'address',
        'postcode',
        'city',
        'state',
        'country',
    ];

    const dateAttrs = [
        'birth_date',
    ];

    interface dataObject {
        [key: string]: any,
    };

    const formatValue = (attr: string, value: any) => {
        if(dateAttrs.includes(attr)) {
            return value != undefined && value != null ? new Date(value) : null;
        }
        else {
            return value != undefined && value != null ? value : null;
        }
    }

    async function formatApiData(data: dataObject) {
        let formatted: dataObject = {};

        topLevelAttrs.map((attr: string) => {
            const value = formatValue(attr, data[attr]);
            if(value !== null) {
                formatted[attr] = value;
            }
        });

        userProfileAttrs.map((attr: string) => {
            const value = data.user_profile != undefined && data.user_profile != null ? formatValue(attr, data.user_profile[attr]) : null;
            if(value !== null) {
                formatted[attr] = value;
            }
        });

        return formatted;
    }

    async function fetchGetProfile() {
        const url = getUrl('userProfileInfo');

        const response = await fetch(url, {
            method: 'GET',
            headers: getAuthHeader(HEADERS_JSON),
        });

        const {data, message} = await response.json();
        
        if(response.ok) {
            const formatted = await formatApiData(data);
            setUserAttrs({
                ...userAttrs,
                ...formatted,
            });
        }
        else {
            //console.log('error', message);
        }
    }

    async function fetchPutProfile() {
        const url = getUrl('userProfileInfo');

        const { id, birth_date, ...inputData } = userAttrs;

        const requestBody = {
            ...inputData,
            birth_date: format(birth_date, DATE_FORMAT_SHORT),
        };

        const response = await fetch(url, {
            method: 'PUT',
            headers: getAuthHeader(HEADERS_JSON),
            body: JSON.stringify(requestBody),
        });

        if(response.ok) {
            setProfileUpdated(new Date());
            history.push('/edit-profile-success');
        }
        else if(response.status === 422) {
            const { errors } = await response.json();

            const errorAttrs = Object.keys(errors);
            const errorStatuses = Object.fromEntries(errorAttrs.map((_, i) => [errorAttrs[i], true]));
            
            setInputErrors({
                ...inputErrors,
                ...errorStatuses,
            })
            setInputErrorMessages({
                ...inputErrorMessages,
                ...errors,
            });
        }
        else {
            console.log('error');
        }
        
    }

    const handleUpdateButtonClick = () => {
        fetchPutProfile();
    }

    useEffect(() => {
        fetchGetProfile();
    }, []);
    
    return (
        <Box sx={{
            flexGrow: 1,
            margin: '32px',
        }}>
            <Container maxWidth="sm">
                <form>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                id="email"
                                name="email"
                                label="Email"
                                type="email"
                                value={userAttrs.email}
                                error={inputErrors.email}
                                helperText={inputErrors.email ? inputErrorMessages.email.join('. ') : ''}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                onChange={handleChangeTextField}
                                onBlur={(e) => {
                                    const isValid = EmailValidator.validate(e.target.value);
                                    setInputErrors({
                                        ...inputErrors,
                                        [e.target.name]: !isValid,
                                    });
                                    setInputErrorMessages({
                                        ...inputErrorMessages,
                                        [e.target.name]: [VALIDATION_MESSAGES.email],
                                    });
                                }}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                id="name"
                                name="name"
                                label="Name"
                                type="text"
                                value={userAttrs.name}
                                error={inputErrors.name}
                                helperText={inputErrors.name ? inputErrorMessages.name.join('. ') : ''}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                onChange={handleChangeTextField}
                                onBlur={(e) => {
                                    const isValid = e.target.value.length > 0;
                                    setInputErrors({
                                        ...inputErrors,
                                        [e.target.name]: !isValid,
                                    });
                                    setInputErrorMessages({
                                        ...inputErrorMessages,
                                        [e.target.name]: [VALIDATION_MESSAGES.name],
                                    });
                                }}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <FormControl sx={{ mt: 2, width: 268 }} error={inputErrors.nationality}>
                                <InputLabel id="label-nationality">Nationality</InputLabel>
                                <Select
                                    id="nationality"
                                    name="nationality"
                                    label="Nationality"
                                    labelId="label-nationality"
                                    value={userAttrs.nationality}
                                    onChange={(e) => {
                                        handleChangeSelect(e);
                                        setInputErrors({
                                            ...inputErrors,
                                            national_id: false,
                                            passport_issuer: false,
                                            passport_no: false,
                                        });
                                    }}
                                    onBlur={(e) => {
                                        const isValid = e.target.value.length > 0;
                                        setInputErrors({
                                            ...inputErrors,
                                            [e.target.name]: !isValid,
                                        });
                                        setInputErrorMessages({
                                            ...inputErrorMessages,
                                            [e.target.name]: [VALIDATION_MESSAGES.nationality],
                                        });
                                    }}
                                >{
                                    COUNTRIES.map(country => <MenuItem
                                        key={`nationality-${country.value}`}
                                        value={country.value}
                                    >
                                        {country.label}
                                    </MenuItem>)
                                }</Select>
                                <FormHelperText>{inputErrors.nationality ? inputErrorMessages.nationality.join('. ') : ''}</FormHelperText>
                            </FormControl>
                        </Grid>

                        {
                            userAttrs.nationality === 'MY' ? (
                                <Grid item xs={12}>
                                    <TextField
                                        id="national_id"
                                        name="national_id"
                                        label="National ID"
                                        type="text"
                                        value={userAttrs.national_id}
                                        error={inputErrors.national_id}
                                        helperText={inputErrors.national_id ? inputErrorMessages.national_id.join('. ') : ''}
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        onChange={handleChangeTextField}
                                        onBlur={(e) => {
                                            const isValid = userAttrs.nationality === "MY" ? (e.target.value.length > 0) : true;
                                            setInputErrors({
                                                ...inputErrors,
                                                [e.target.name]: !isValid,
                                            });
                                            setInputErrorMessages({
                                                ...inputErrorMessages,
                                                [e.target.name]: [VALIDATION_MESSAGES.national_id],
                                            });
                                        }}
                                    />
                                </Grid>
                            ) : (
                                <>
                                    <Grid item xs={6}>
                                        <FormControl sx={{ mt: 2, width: 268 }} error={inputErrors.passport_issuer}>
                                            <InputLabel id="label-passport-issuer">Passport Issuer</InputLabel>
                                            <Select
                                                id="passport_issuer"
                                                name="passport_issuer"
                                                label="Passport Issuer"
                                                labelId="label-passport-issuer"
                                                value={userAttrs.passport_issuer}
                                                onChange={handleChangeSelect}
                                                onBlur={(e) => {
                                                    const isValid = userAttrs.nationality !== "MY" ? (e.target.value.length > 0) : true;
                                                    setInputErrors({
                                                        ...inputErrors,
                                                        [e.target.name]: !isValid,
                                                    });
                                                    setInputErrorMessages({
                                                        ...inputErrorMessages,
                                                        [e.target.name]: [VALIDATION_MESSAGES.passport_issuer],
                                                    });
                                                }}
                                            >{
                                                COUNTRIES.map(country => <MenuItem
                                                    key={`country-${country.value}`}
                                                    value={country.value}
                                                >
                                                    {country.label}
                                                </MenuItem>)
                                            }</Select>
                                            <FormHelperText>{inputErrors.passport_issuer ? inputErrorMessages.passport_issuer.join('. ') : ''}</FormHelperText>
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={6}>
                                        <TextField
                                            id="passport_no"
                                            name="passport_no"
                                            label="Passport No."
                                            type="text"
                                            value={userAttrs.passport_no}
                                            error={inputErrors.passport_no}
                                            helperText={inputErrors.passport_no ? inputErrorMessages.passport_no.join('. ') : ''}
                                            variant="outlined"
                                            margin="normal"
                                            fullWidth
                                            onChange={handleChangeTextField}
                                            onBlur={(e) => {
                                                const isValid = userAttrs.nationality !== "MY" ? (e.target.value.length > 0) : true;
                                                setInputErrors({
                                                    ...inputErrors,
                                                    [e.target.name]: !isValid,
                                                });
                                                setInputErrorMessages({
                                                    ...inputErrorMessages,
                                                    [e.target.name]: [VALIDATION_MESSAGES.passport_no],
                                                });
                                            }}
                                        />
                                    </Grid>
                                </>
                            )
                        }

                        <Grid item xs={12}>
                            <FormControl sx={{ mt: 2, width: 268 }} error={inputErrors.gender}>
                                <InputLabel id="label-gender">Gender</InputLabel>
                                <Select
                                    id="gender"
                                    name="gender"
                                    label="Gender"
                                    labelId="label-gender"
                                    value={userAttrs.gender}
                                    onChange={handleChangeSelect}
                                    onBlur={(e) => {
                                        const isValid = e.target.value.length > 0;
                                        setInputErrors({
                                            ...inputErrors,
                                            [e.target.name]: !isValid,
                                        });
                                        setInputErrorMessages({
                                            ...inputErrorMessages,
                                            [e.target.name]: [VALIDATION_MESSAGES.gender],
                                        });
                                    }}
                                >{
                                    GENDERS.map(gender => <MenuItem
                                        key={`gender-${gender.value}`}
                                        value={gender.value}
                                    >
                                        {gender.label}
                                    </MenuItem>)
                                }</Select>
                                <FormHelperText>{inputErrors.gender ? inputErrorMessages.gender.join('. ') : ''}</FormHelperText>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12}>
                            <FormControl sx={{ mt: 2, width: 268 }}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        label="Birth Date"
                                        value={userAttrs.birth_date}
                                        maxDate={maxBirthDate}
                                        inputFormat={DATE_FORMAT_SHORT}
                                        onChange={(date) => {
                                            setUserAttrs({
                                                ...userAttrs,
                                                birth_date: date as Date,
                                            });
                                        }}
                                        onError={(reason, value) => {
                                            setInputErrors({
                                                ...inputErrors,
                                                birth_date: true,
                                            });
                                            setInputErrorMessages({
                                                ...inputErrorMessages,
                                                birth_date: [getDatePickerErrorMessage(reason, value, null, maxBirthDate)],
                                            });
                                        }}
                                        renderInput={(params) => <TextField {...params} helperText={inputErrors.birth_date ? inputErrorMessages.birth_date.join('. ') : ''} />}
                                    />
                                </LocalizationProvider>
                            </FormControl>
                        </Grid>
                        
                        <Grid item xs={12}>
                            <TextField
                                id="phone_mobile"
                                name="phone_mobile"
                                label="Phone (Mobile)"
                                type="text"
                                value={userAttrs.phone_mobile}
                                error={inputErrors.phone_mobile}
                                helperText={inputErrors.phone_mobile ? inputErrorMessages.phone_mobile.join('. ') : ''}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                onChange={handleChangeTextField}
                                onBlur={(e) => {
                                    const isValid = e.target.value.length > 0;
                                    setInputErrors({
                                        ...inputErrors,
                                        [e.target.name]: !isValid,
                                    });
                                    setInputErrorMessages({
                                        ...inputErrorMessages,
                                        [e.target.name]: [VALIDATION_MESSAGES.phone_mobile],
                                    });
                                }}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                id="phone_home"
                                name="phone_home"
                                label="Phone (Home)"
                                type="text"
                                value={userAttrs.phone_home}
                                error={inputErrors.phone_home}
                                helperText={inputErrors.phone_home ? inputErrorMessages.phone_home.join('. ') : ''}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                onChange={handleChangeTextField}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                id="phone_office"
                                name="phone_office"
                                label="Phone (Office)"
                                type="text"
                                value={userAttrs.phone_office}
                                error={inputErrors.phone_office}
                                helperText={inputErrors.phone_office ? inputErrorMessages.phone_office.join('. ') : ''}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                onChange={handleChangeTextField}
                            />
                        </Grid>
                        
                        <Grid item xs={12}>
                            <TextField
                                id="address"
                                name="address"
                                label="Address"
                                type="text"
                                value={userAttrs.address}
                                error={inputErrors.address}
                                helperText={inputErrors.address ? inputErrorMessages.address.join('. ') : ''}
                                multiline
                                minRows={3}
                                maxRows={5}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                onChange={handleChangeTextField}
                                onBlur={(e) => {
                                    const isValid = e.target.value.length > 0;
                                    setInputErrors({
                                        ...inputErrors,
                                        [e.target.name]: !isValid,
                                    });
                                    setInputErrorMessages({
                                        ...inputErrorMessages,
                                        [e.target.name]: [VALIDATION_MESSAGES.address],
                                    });
                                }}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                id="postcode"
                                name="postcode"
                                label="Postcode"
                                type="text"
                                value={userAttrs.postcode}
                                error={inputErrors.postcode}
                                helperText={inputErrors.postcode ? inputErrorMessages.postcode.join('. ') : ''}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                onChange={handleChangeTextField}
                                onBlur={(e) => {
                                    const isValid = e.target.value.length > 0;
                                    setInputErrors({
                                        ...inputErrors,
                                        [e.target.name]: !isValid,
                                    });
                                    setInputErrorMessages({
                                        ...inputErrorMessages,
                                        [e.target.name]: [VALIDATION_MESSAGES.postcode],
                                    });
                                }}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                id="city"
                                name="city"
                                label="City"
                                type="text"
                                value={userAttrs.city}
                                error={inputErrors.city}
                                helperText={inputErrors.city ? inputErrorMessages.city.join('. ') : ''}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                onChange={handleChangeTextField}
                                onBlur={(e) => {
                                    const isValid = e.target.value.length > 0;
                                    setInputErrors({
                                        ...inputErrors,
                                        [e.target.name]: !isValid,
                                    });
                                    setInputErrorMessages({
                                        ...inputErrorMessages,
                                        [e.target.name]: [VALIDATION_MESSAGES.city],
                                    });
                                }}
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <FormControl sx={{ mt: 2, width: 268 }} error={inputErrors.country}>
                                <InputLabel id="label-country">Country</InputLabel>
                                <Select
                                    id="country"
                                    name="country"
                                    label="Country"
                                    labelId="label-country"
                                    value={userAttrs.country}
                                    onChange={(e) => {
                                        handleChangeSelect(e);
                                        setInputErrors({
                                            ...inputErrors,
                                            state: false,
                                        });
                                    }}
                                    onBlur={(e) => {
                                        const isValid = e.target.value.length > 0;
                                        setInputErrors({
                                            ...inputErrors,
                                            [e.target.name]: !isValid,
                                        });
                                        setInputErrorMessages({
                                            ...inputErrorMessages,
                                            [e.target.name]: [VALIDATION_MESSAGES.country],
                                        });
                                    }}
                                >{
                                    COUNTRIES.map(country => <MenuItem
                                        key={`country-${country.value}`}
                                        value={country.value}
                                    >
                                        {country.label}
                                    </MenuItem>)
                                }</Select>
                                <FormHelperText>{inputErrors.country ? inputErrorMessages.country.join('. ') : ''}</FormHelperText>
                            </FormControl>
                        </Grid>

                        <Grid item xs={6}>
                            {
                                userAttrs.country === 'MY' ? (
                                <FormControl sx={{ mt: 2, minWidth: 268 }} error={inputErrors.state}>
                                    <InputLabel id="label-state-my">State</InputLabel>
                                    <Select
                                        id="state_my"
                                        name="state"
                                        label="State"
                                        labelId="label-state-my"
                                        value={userAttrs.state}
                                        onChange={handleChangeSelect}
                                        onBlur={(e) => {
                                            const isValid = e.target.value.length > 0;
                                            setInputErrors({
                                                ...inputErrors,
                                                [e.target.name]: !isValid,
                                            });
                                            setInputErrorMessages({
                                                ...inputErrorMessages,
                                                [e.target.name]: [VALIDATION_MESSAGES.state_my],
                                            });
                                        }}
                                    >{
                                        MY_STATES.map(state => <MenuItem
                                            key={`state_my-${state.value}`}
                                            value={state.value}
                                        >
                                            {state.label}
                                        </MenuItem>)
                                    }</Select>
                                    <FormHelperText>{inputErrors.state ? inputErrorMessages.state.join('. ') : ''}</FormHelperText>
                                </FormControl>
                                ) : (
                                <TextField
                                    id="state"
                                    name="state"
                                    label="State"
                                    type="text"
                                    value={userAttrs.state}
                                    error={inputErrors.state}
                                    helperText={inputErrors.state ? inputErrorMessages.state.join('. ') : ''}
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    onChange={handleChangeTextField}
                                    onBlur={(e) => {
                                        const isValid = e.target.value.length > 0;
                                        setInputErrors({
                                            ...inputErrors,
                                            [e.target.name]: !isValid,
                                        });
                                        setInputErrorMessages({
                                            ...inputErrorMessages,
                                            [e.target.name]: [VALIDATION_MESSAGES.state],
                                        });
                                    }}
                                />
                                )
                            }
                        </Grid>

                        <Box sx={{m: 1}} />

                        <Grid item xs={12}>
                            <Button
                                size="large"
                                variant="contained"
                                fullWidth
                                onClick={handleUpdateButtonClick}
                                startIcon={<EditIcon />}
                            >{EDIT_PROFILE_NAME}</Button>
                        </Grid>

                        <Grid item xs={12}>
                            <Button
                                size="large"
                                variant="outlined"
                                fullWidth
                                onClick={() => {history.goBack()}}
                            >Cancel</Button>
                        </Grid>
                    </Grid>
                </form>
            </Container>
        </Box>
    );
};

interface PageEditProfileProps {
    user: any,
    isFetched: boolean,
    setProfileUpdated: Function,
};

const PageEditProfile: React.FC<PageEditProfileProps> = (props: PageEditProfileProps) => {
    const history = useHistory();

    useEffect(() => {
        if(props.isFetched && props.user == null) {
            history.push('/login');
        }
    }, [props.isFetched, props.user]);

    return props.isFetched ? (
        <IonPage>
            <IonHeader>
                <MyAppBar
                    title={EDIT_PROFILE_NAME}
                />
            </IonHeader>
            <IonContent>
                <FormEditProfile setProfileUpdated={props.setProfileUpdated} />
            </IonContent>
        </IonPage>
    ) : null;
};

export default PageEditProfile;