import React from "react";
import { useHistory } from "react-router-dom";
import { 
    IonContent,
    IonHeader,
    IonPage,
} from '@ionic/react';
import LoginIcon from '@mui/icons-material/Login';
import { 
    Box,
    Button,
    Container,
    Grid,
} from '@mui/material';

import MyAppBar from "../components/MyAppBar";
import {
    RESET_SUCCESS_NAME,
    RESET_SUCCESS_TITLE,
    RESET_SUCCESS_TEXT,
    LOGIN_NAME,
} from "../components/Global";
import "./TabForgotSuccess.css";

interface TabResetSuccessProps {

}

const TabResetSuccess: React.FC<TabResetSuccessProps> = (props: TabResetSuccessProps) =>  {
    const history = useHistory();

    const handleClick = () => {
        history.push('/login');
    }

    return (
        <IonPage>
            <IonHeader>
                <MyAppBar
                    title={RESET_SUCCESS_NAME}
                />
            </IonHeader>
            
            <IonContent>
                <Box sx={{
                flexGrow: 1,
                margin: '32px',
                }}>
                    <Container maxWidth="sm">
                        <form>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <h1>{RESET_SUCCESS_TITLE}</h1>
                                    <>{RESET_SUCCESS_TEXT}</>
                                </Grid>

                                <Box sx={{m: 1}} />

                                <Grid item xs={12}>
                                    <Button
                                        size="large"
                                        variant="contained"
                                        fullWidth
                                        onClick={handleClick}
                                        startIcon={<LoginIcon />}
                                    >{LOGIN_NAME}</Button>
                                </Grid>

                            </Grid>
                        </form>
                    </Container>
                </Box>
            </IonContent>
        </IonPage>
    )
};

export default TabResetSuccess;