import { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { getUrl } from "./Urls";
import { getAuthHeader } from "./Auth";
import { HEADERS_JSON } from "./Global";

export function useTenantHashid() {
    const { search } = useLocation();
    const searchParams = useMemo(() => new URLSearchParams(search), [search]);

    const t = searchParams.get('t');
    return t;
};

export function useVerifyTenant() {
    const t = useTenantHashid();

    const [response, setResponse] = useState<any>(null);

    useEffect(() => {
        if(t) {
            fetchVerifyTenant(t);
        }
    }, []);

    async function fetchVerifyTenant(hashid: string) {
        const url = `${getUrl('tenantVerify')}?t=${hashid}`;

        const response = await fetch(url, {
            method: 'GET',
            headers: getAuthHeader(HEADERS_JSON),
        });

        const { data } = await response.json();
        
        if(response.ok) {
            setResponse(data);
        }
        else {
            console.log('Invalid tenant');
        }
    }

    return response;
}